import spinner from "./spinner";

const BUTTON_CONTAINER_CLASS = "apple-ttp-button";
const BUTTON_CLASS = "apple-ttp-button-link";
const BUTTON_TEXT = "Connect Tap to Pay on iPhone";

enum Envs {
  Dev = "dev",
  Prod = "prod",
}

enum LinkingStatus {
  Success = "success",
  Error = "error",
}

type LinkingPayload = {
  status: LinkingStatus;
  incidentId?: string;
  merchantId?: string;
};

const envUrls = {
  [Envs.Dev]:
    "https://bp-demo02.geo.apple.com/m4m-api/generate-redirect-with-token",
  [Envs.Prod]:
    "https://bp-qa.geo.apple.com/tap-to-pay-on-iphone-api/v1/merchants/redirect",
};

interface AppleTtpButtonManagerConfig {
  apiCallback: () => Promise<string>;
  linkingCompletionHandler: (linkingPayload: LinkingPayload) => {};
  buttonText?: string;
  customButtonClass?: string;
  env?: Envs;
  customApiCallbackErrorHandler?: (e: Error) => void;
  windowWidth?: number;
  windowHeight?: number;
}

class AppleTtpButtonManager {
  private apiCallback: () => Promise<string>;
  private linkingCompletionHandler: (linkingPayload: LinkingPayload) => {};
  private buttonText: string;
  private customButtonClass: string;
  private env: Envs;
  private customApiCallbackErrorHandler?: (e: Error) => void;
  private windowWidth: number;
  private windowHeight: number;
  private redirectUrl?: string;
  private jwtToken?: string;
  private isFetchingToken?: boolean;

  constructor({
    apiCallback,
    linkingCompletionHandler,
    buttonText = BUTTON_TEXT,
    customButtonClass = "",
    env = Envs.Prod,
    windowWidth = 530,
    windowHeight = 700,
  }: AppleTtpButtonManagerConfig) {
    this.apiCallback = apiCallback;
    this.linkingCompletionHandler = linkingCompletionHandler;
    this.buttonText = buttonText;
    this.customButtonClass = customButtonClass;
    this.env = env;
    this.windowWidth = windowWidth;
    this.windowHeight = windowHeight;

    this.validateConfig();
  }

  public async refresh() {
    if (this.isFetchingToken) {
      return;
    }

    // Attach buttons to HTML containers
    const containers: NodeListOf<HTMLDivElement> = document.querySelectorAll(
      `.${BUTTON_CONTAINER_CLASS}`
    );

    containers.forEach((container) => {
      container.innerHTML = "";
      const button = this.createButtonHTMLElement();
      container.appendChild(button);
    });

    this.forEachButton((button) => {
      button.removeEventListener("click", this.handleGenerateTokenClick);
      button.addEventListener("click", this.handleGenerateTokenClick);
    });

    window.removeEventListener("message", (event) =>
      this.handlePostMessage(event)
    );
    window.addEventListener("message", (event) =>
      this.handlePostMessage(event)
    );

    this.forEachButton((button) => {
      button.innerHTML = spinner;
    });

    this.isFetchingToken = true;

    // Fetch Token using provided callback
    try {
      this.jwtToken = await this.apiCallback();
    } catch (e) {
      if (this.customApiCallbackErrorHandler) {
        this.customApiCallbackErrorHandler(e);
      } else {
        throw e;
      }
    } finally {
      if (typeof this.jwtToken !== "string") {
        this.resetButtonAndThrowError(
          "AppleTtpButtonManager: apiCallback must return a string token"
        );
      }
    }

    // Fetch redirect URL using token
    try {
      const rawResponse = await fetch(envUrls[this.env], {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          merchantToken: this.jwtToken,
        }),
      });

      const json = await rawResponse.json();
      this.redirectUrl = json.redirectUrl;
    } catch (e) {
      this.resetButtonAndThrowError(
        "AppleTtpButtonManager: failed to fetch redirect URL from Apple Business Register"
      );
    }

    this.isFetchingToken = false;
    this.forEachButton((button) => {
      button.innerHTML = this.buttonText;
    });
  }

  public handlePostMessage(event: MessageEvent<any>) {
    if (
      event.origin !== "https://bp-demo02.geo.apple.com" &&
      event.origin !== "https://bp-qa.geo.apple.com" &&
      event.origin !== "https://register.apple.com"
    ) {
      return;
    }

    this.linkingCompletionHandler(event.data);
  }

  private createButtonHTMLElement(): HTMLAnchorElement {
    const a = document.createElement("a");

    a.textContent = this.buttonText;

    a.setAttribute("href", "");
    a.setAttribute("target", "_blank");
    if (this.customButtonClass) {
      a.classList.add(this.customButtonClass);
    }

    a.classList.add(BUTTON_CLASS);

    return a;
  }

  private validateConfig(): void {
    if (!this.apiCallback) {
      throw new Error(
        "AppleTtpButtonManager: please provide a function for apiCallback"
      );
    }
  }

  private handleGenerateTokenClick = (e: MouseEvent) => {
    e.preventDefault();

    const screenLeft =
      window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const screenTop =
      window.screenTop !== undefined ? window.screenTop : window.screenY;

    const left = window.outerWidth / 2 + screenLeft - this.windowWidth / 2;
    const top = window.outerHeight / 2 + screenTop - this.windowHeight / 2;

    const newWindow = window.open(
      this.redirectUrl,
      "Tap to Pay on iPhone",
      `
        scrollbars=yes,
        width=${this.windowWidth},
        height=${this.windowHeight},
        top=${top},
        left=${left}
      `
    );

    return newWindow;
  };

  private forEachButton(callback: (button: HTMLAnchorElement) => void) {
    (
      document.querySelectorAll(
        `a.${BUTTON_CLASS}`
      ) as NodeListOf<HTMLAnchorElement>
    ).forEach(callback);
  }

  private resetButtonAndThrowError(message: string): void {
    this.forEachButton((button) => {
      button.innerHTML = this.buttonText;
    });
    throw new Error(message);
  }
}

if (window) {
  (<any>window).AppleTtpButtonManager = AppleTtpButtonManager;
}
