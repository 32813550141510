const spinner = `<?xml version="1.0" encoding="UTF-8"?>
<svg class="ttp-loading-spinner" viewBox="0 0 70 70" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <g fill="currentColor">
    <rect transform="rotate(  0 35 35)" fill-opacity="1.00" x="32" y="0" width="5" height="18" rx="3"></rect>
    <rect transform="rotate( 30 35 35)" fill-opacity="0.12" x="32" y="0" width="5" height="18" rx="3"></rect>
    <rect transform="rotate( 60 35 35)" fill-opacity="0.20" x="32" y="0" width="5" height="18" rx="3"></rect>
    <rect transform="rotate( 90 35 35)" fill-opacity="0.28" x="32" y="0" width="5" height="18" rx="3"></rect>
    <rect transform="rotate(120 35 35)" fill-opacity="0.36" x="32" y="0" width="5" height="18" rx="3"></rect>
    <rect transform="rotate(150 35 35)" fill-opacity="0.44" x="32" y="0" width="5" height="18" rx="3"></rect>
    <rect transform="rotate(180 35 35)" fill-opacity="0.52" x="32" y="0" width="5" height="18" rx="3"></rect>
    <rect transform="rotate(210 35 35)" fill-opacity="0.60" x="32" y="0" width="5" height="18" rx="3"></rect>
    <rect transform="rotate(240 35 35)" fill-opacity="0.68" x="32" y="0" width="5" height="18" rx="3"></rect>
    <rect transform="rotate(270 35 35)" fill-opacity="0.76" x="32" y="0" width="5" height="18" rx="3"></rect>
    <rect transform="rotate(300 35 35)" fill-opacity="0.84" x="32" y="0" width="5" height="18" rx="3"></rect>
    <rect transform="rotate(330 35 35)" fill-opacity="0.92" x="32" y="0" width="5" height="18" rx="3"></rect>
  </g>
</svg>
`;

export default spinner;
